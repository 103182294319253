import { electronicExpressWaybillService, logisticsService, senderService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import {
  ElectronicBillDeliverySave,
  ElectronicExpressWaybillTemplate,
  LogisticsList,
  SenderResource
} from '@/resource/model';
import { messageError, messageErrors, translation } from '@/utils';
import { MessageBox } from 'element-ui';
import { DatePickerOptions } from 'element-ui/types/date-picker';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ElectronicBillDelivery extends mixins(DialogMixin) {
  @Prop({ required: true, type: Array })
  public bills!: Array<LogisticsList>;

  /**
   * 电子面单发货需要的参数
   */
  public electronicBillForm: ElectronicBillDeliverySave = {
    idList: [],
    electronTemplateId: undefined,
    senderTemplateId: undefined,
    expType: '',
    startGotTime: ''
  };

  public electronicBillFormRules: { [p in keyof ElectronicBillDeliverySave]?: Array<object> } = {
    electronTemplateId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.selectElectronicBill')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    senderTemplateId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('logistics.selectDeliveryUser')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    expType: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('electronicExpressWaybill.selectExpressType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    startGotTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('logistics.selectStartGotTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public senderTemplate: Array<SenderResource> = [];

  public electronicBillTemplate: Array<ElectronicExpressWaybillTemplate> = [];

  /**
   * 快递产品类型
   */
  public expressTypes: Array<{ id: number; name: string }> = [];

  public startGotTimeOptions: DatePickerOptions = {
    disabledDate: (date: Date) => {
      const callbackDateTime = date.getTime();
      const today = new Date();
      const currentDateTime = today.getTime();
      return callbackDateTime <= currentDateTime;
    }
  };

  public created(): void {
    this.getElectronicBillTemplate();
    this.getSenderTemplate();
  }

  /**
   * 
   * 电子面单下单(已废弃)
   */
  public async takeElectronicBill1(): Promise<void> {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      try {
        this.setLoading(true);
        await logisticsService.batchTakeElectronicBill(this.electronicBillForm);
        this.$emit('delivery-success');

        // 询问用户是否需要打印
        await MessageBox.confirm(translation('logistics.confirmPrint'), translation('tip.tipInfo'), {
          confirmButtonText: translation('button.ok'),
          cancelButtonText: translation('button.cancel')
        });
        // this.$emit('print', this.ids);
        this.closeDialog();
      } catch (error) {
        if (error !== 'cancel') messageErrors(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  /**
   * 电子面单下单  TODO 此处为临时解决方案，后面必须改掉
   */
  // TODO   此处为临时解决方案，后面必须改掉
  public async takeElectronicBill(): Promise<void> {
    (this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      for (const item of this.bills) {
        this.electronicBillForm.idList = [item.id];
        try {
          await logisticsService.batchTakeElectronicBill(this.electronicBillForm);
          this.$emit('print', this.electronicBillForm.idList);
        } catch (error) {
          messageErrors(error);
        }
      }

      this.closeDialog();
      this.$emit('delivery-success');
      this.setLoading(false);
    });
  }

  public templateChange(templateId: number): void {
    this.electronicBillForm.expType = '';
    this.getExpressTypeByCompany(
      this.electronicBillTemplate.find(x => x.id === templateId)!.expressCompanyId
    );
  }

  private getSenderTemplate(): void {
    senderService
      .getAllSenders()
      .then(res => {
        this.senderTemplate = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getElectronicBillTemplate(): void {
    electronicExpressWaybillService
      .getAllUsing()
      .then(res => {
        this.electronicBillTemplate = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getExpressTypeByCompany(companyId: number): void {
    electronicExpressWaybillService
      .getExpressTypeByCompany(companyId)
      .then(res => {
        this.expressTypes = res;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 返回根据收货信息分组后的物流单(暂时没用)
   */
  private groupByReceiveInfo(): { [P: string]: Array<LogisticsList> } {
    const res: { [P: string]: Array<LogisticsList> } = {};
    const splitCode = ',';
    let receiveInfo = '';
    this.bills.forEach(bill => {
      receiveInfo =
        bill.receivingProvince +
        bill.receivingCity +
        bill.receivingDistrict +
        bill.receivingAddress +
        splitCode +
        bill.receiver +
        splitCode +
        bill.receivingTel +
        splitCode +
        bill.customerName +
        splitCode +
        bill.remark;
      if (res.hasOwnProperty(receiveInfo)) {
        res[receiveInfo].push(bill);
      } else {
        res[receiveInfo] = [bill];
      }
    });
    return res;
  }
}
